import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '../components/Button.js'

export default function Workshop({ 
    title = "",
    toPath = "",
    whom = "",
    results = "",
    image = false,
    ...others
  }) {

  if(!image) image = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}

  return (    
    <div className="col-md-5 d-flex mb-4">
      <div className="bg-color-white p-2r rounder d-flex flex-column justify-content-between">
        <div className="packing">
          <Button to={toPath}>
            <GatsbyImage
              image={getImage(image.localFile)}
              alt={image.altText} 
              objectFit="cover"
              className="roundr-top overlapped card-feature-image bg-pattern-gradient-yellow"
            />
          </Button>
          <h4 className="h2 mb-2r c-purple">
            {title}
          </h4>
          <p className="c-purple-2 bold">Para quem é?</p>
          <p className="c-grey mb-2r">{whom}</p>

          <p className="c-purple-2 bold">Resultados</p>
          <p className="c-grey">{results}</p>
        </div>
        <Button className="bttn outline c-purple h3 jc-c tac" to={toPath}>
          Saiba mais
        </Button>
      </div>
    </div>
  ) 
}
