import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import EmpresasParceiras from "../common/section/EmpresasParceiras.js"
import FormContatoImageSection from "../common/section/FormContatoImageSection.js"

import Container from "../common/helpers/Container.js"
import ContainerWithBg from "../common/helpers/ContainerWithBg.js"

import Page from "../common/elements/Page.js"
import Workshop from "../common/elements/Workshop.js"

export default function Workshops({data}){

  const fields = data.wpPage ? data.wpPage.paginaWorkshopsFields : null

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/bg-workshop-hero.png"></link>
      </Helmet>
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-workshop-hero py-8 py-lg-0"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white feature-image"
      >
        <h1 className="z1 h1-egg mb-15r">Workshops</h1>
        <p className="z1">
          Soluções e conteúdos aplicados à realidade da sua empresa
        </p>
      </ContainerWithBg>

      <ContainerWithBg
        classBgName="bg-color-purple-3 mt--2r pb-4r"
        className="container-xl"
      >
        <div className="row justify-content-center">
          { 
            data.allWpWorkshop.nodes.map(node => (
              <Workshop 
                title={node.title}
                whom={node.workshopFields.comunicacaoWorkshopQuem}
                results={node.workshopFields.comunicacaoWorkshopResultados}
                image={(node.featuredImage) ? node.featuredImage.node : null}
                toPath={node.link}
                key={node.id}
              />
            ))
          }
        </div>
      </ContainerWithBg>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex p-0  bg-color-purple c-white p-rf">
            <StaticImage alt="Differentiation" src="../images/img-workshop.png" />
          </div>
          <div className="col-md-6 bg-color-purple bg-color-purple d-flex justify-content-center">
            <div className="mw-43r p-2r">
              <h3 className="h3 c-golden mb-4">
                Por que nos contratar?
              </h3>
              <ul className="list-default c-white">
                <li>Profissionais que já ocuparam cargos de gestão e liderança em projetos e empresas de médio e grande porte (incluindo multinacionais);</li>
                <li>Experiência prática e acadêmica (nosso quadro é composto por mestres e docentes das maiores universidades do país);</li>
                <li>Produção e atualização contínua (investimos na capacitação com pares e referências nas áreas de gestão, planejamento de comunicação integrada, estratégia e projetos de comunicação).</li>                
              </ul>
            </div>
          </div>
        </div>
      </Container> 
      
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-gold bg-image-workshop pb-4r pt-4r"
        className="container-md tac c-white z1 p-r dfc"
      >
        <div className="line-lenght-medium m-auto">
          <h2 className="h2 mb-2r c-purple">
            { fields.comunicacaoPagworkshopDiferenciais.comunicacaoPagworkshopDiferenciaisTitulo }
          </h2>
          <div 
            dangerouslySetInnerHTML={{ 
              __html: fields.comunicacaoPagworkshopDiferenciais.comunicacaoPagworkshopDiferenciaisConteudo 
            }}  
            className="mb-1r c-purple" 
          />
        </div>
      </ContainerWithBg>
      
      

      {/* <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 bg-color-purple bg-color-purple d-flex justify-content-center">
            <div className="mw-43r p-2r">
              <h3 className="h3 c-white mb-0">
                Conheça nossos Workshops ministrados  Diagnóstico e Planejamento de Comunicação Integrada, Gestão de Riscos Reputacionais e Mapeamento de Fluxos e Processos, com experiência de mais de 10 anos em projetos para empresas de médio e grande porte, nacionais e multinacionais.
              </h3>
            </div>
          </div>
          <div className="col-md-6 d-flex p-0  bg-color-purple c-white p-rf">
            <StaticImage alt="Differentiation" src="../images/img-workshop.png" />
          </div>
        </div>
      </Container> */}
      
      <EmpresasParceiras />

      <FormContatoImageSection />

      
    </Page>
  )
}

export const pageQuery = graphql`
  query {
    allWpWorkshop(sort: { fields: [title] }) {
      nodes {
        id
        title
        link
        workshopFields {
          comunicacaoWorkshopQuem
          comunicacaoWorkshopResultados
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 19051}) {
      nodeType
      title
      uri
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
      paginaWorkshopsFields {
        comunicacaoPagworkshopDiferenciais {
          comunicacaoPagworkshopDiferenciaisConteudo
          comunicacaoPagworkshopDiferenciaisTitulo
        }
      }
    }
  }
`